import React, {createContext, useContext, useState} from 'react';
import { removeToken } from "../helpers/localStorage";


const AuthContext = createContext ({});

const { Provider } = AuthContext;

  

const AuthProvider =({ children }) =>{
    const token = localStorage.getItem('ETH_CUSTOMER_CARE_TOKEN');
    const token2 = localStorage.getItem('ETH_CUSTOMER_CARE_TOKEN2');

    const expiresAt = localStorage.getItem('ETH_CUSTOMER_CARE_EXPIRES_AT');
    // const role = localStorage.getItem('AGGREGATOR_CMS_ROLE');
    var retrievedPerson = JSON.parse(localStorage.getItem('userData'));
    const email = retrievedPerson?.email
    const name = retrievedPerson?.name

    const id = retrievedPerson?.id
    const phone = retrievedPerson?.phone
    const role = retrievedPerson?.user_type


    const [authState, setAuthState] = useState({
        token,
        token2,
        expiresAt,
        // role,
        email,
        id,
        phone,
        name

    })
    const setAuthInfo = ({token, token2, expiresAt, role, email, id, phone, name}) =>{

        setAuthState({
          token,
            token2,
            expiresAt,
            // role,
            email,
            id,
            phone,
            name

        })

    }
 

    const isAuthenticated = () => {
        let currentTime = new Date();
        // var timeOfExpiryString =  localStorage.getItem('AGGREGATOR_CMS_EXPIRES_AT')
        let timeOfExpiryObject = new Date(localStorage.getItem('ETH_CUSTOMER_CARE_EXPIRES_AT'))
        console.log(currentTime, authState.token, timeOfExpiryObject, authState.expiresAt, 'LLLLLLL', new Date())

        if (!authState.token || !authState.expiresAt) {
            return false;
        }
        if(currentTime > timeOfExpiryObject ){
          return false;
        }

      return true
    };
   

    const userData = () =>{
      return authState
    }

    const isAdmin = () => {
      return authState.role === 'ADMIN';
    };

    const isReseller = () => {
        return authState.role === 'RESELLER';
      };

    const isBussiness = () => {
        return authState.role === 'BUSINESS';
      };

      const isNormal = () => {
        return authState.role === 'NORMAL';
      };

      const isUndefined = () => {
        return authState.role === '';
      };

    return(
        <Provider value={{
            authState,
            setAuthState: (authInfo) => setAuthInfo(authInfo),
            isAuthenticated,
isAdmin,
isReseller,
isNormal,
isBussiness,
userData
        }}>
            {children}
        </Provider>
    )
}

export {AuthContext, AuthProvider}