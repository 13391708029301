import React, { lazy, Suspense, useContext } from "react";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Outlet,
  Navigate,
  Router,
  Redirect,
  Routes,
} from "react-router-dom";
import { AuthContext } from "./context/AuthContext";

const Login = lazy(() => import("./pages/authentication/Login"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const CustomerDetails = lazy(() => import("./pages/details/CustomerDetails"));
const Borrow = lazy(() => import("./pages/borrow/Borrow"));

const Subscribe = lazy(() => import("./pages/subscribe/Subscribe"));

const TermsAndConditions = lazy(() =>
  import("./pages/terms/TermsAndConditions")
);
const PageUnavailable = lazy(() =>
  import("./pages/unavailable/PageUnavailable")
);
const ContactUs = lazy(() => import("./pages/contact/ContactUs"));

function App() {


  const PrivateRoute = () => {
    const auth = useContext(AuthContext);

    return auth.isAuthenticated() ? <Outlet /> : <Navigate to="/" />;
}


const baseUrl = process.env.PUBLIC_URL;
  
  return (
    <BrowserRouter basename={baseUrl}>
      <Suspense>
        <Routes>
          <Route path="/" exact Component={Login} />
          <Route exact path='/dashboard' element={<PrivateRoute/>}>
            <Route exact path='/dashboard' element={<Dashboard/>}/>
          </Route>
          <Route exact path='/details/:phone' element={<PrivateRoute/>}>
            <Route exact path='/details/:phone' element={<CustomerDetails/>}/>
          </Route>


          <Route exact path='/borrow' element={<PrivateRoute/>}>
            <Route exact path='/borrow' element={<Borrow/>}/>
          </Route>

          <Route exact path='/subscribe' element={<PrivateRoute/>}>
            <Route exact path='/subscribe' element={<Subscribe/>}/>
          </Route>

          
          <Route path="/contact-us" exact Component={ContactUs} />
          <Route
            path="/terms-and-conditions"
            exact
            Component={TermsAndConditions}
          />
          <Route path='*' exact Component={PageUnavailable}/>

        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
