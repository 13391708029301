import axios from "axios";
import { getToken2 } from "../helpers/localStorage";
import { Link, useHistory } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;
// console.log(apiUrl, "hhhhh", getToken2())
  // const url1 = "http://91.109.117.92:8021/api/v1/"
  const url1 = apiUrl
  // const url2 = "http://91.109.117.92:8001/api/v1/"

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: "Bearer " + getToken2(),
};

const instance = axios.create({
  // baseURL: apiUrl,
  baseURL: url1+'/api/v1',

  timeout: 500000,
  headers,
});

function redirectToLogin() {
  let publicUrl = process.env.PUBLIC_URL;
  let loginPageUrl = null;
  loginPageUrl = publicUrl + "/";

  let origin = window.location.origin;
  if (window.location.origin === origin + loginPageUrl) return;
  window.location.href = loginPageUrl;
}

instance.interceptors.response.use(
  function (response) {
    let finalResponse = { ...response, status: 200, statusCode: 200 };
    return finalResponse;
  },
  function (error) {
    if (error?.response?.status === 401) {
      return redirectToLogin();
    } else {
      let finalResponse = {
        ...error,
        status: error?.response?.status,
        statusCode: error?.response?.status,
      };
      return finalResponse;
    }
  }
);

function processResult(response) {
  // console.log(response, 'kkkk')
  let { statusCode } = response;
  try {
    if (statusCode === 200) {
      return {
        success: (response?.status === 200 || response?.status === 201) ? true : false,
        data: response.data.data,
        message: response.data.message,
        status_code: 200,
      };
    } else if (statusCode === 500) {
      return { success: false, message: "Someting went wrong" };
    }
    return {
      success: response.response.data.status,
      message: response.response.data.message,
    };
  } catch (error) {
    // console.log(error.response.response.data.status);
    // console.log({...error});

    return {
      error: error?.response?.response?.data?.status || 500,
      message: error?.response?.response?.data?.message || 'something went wrong please try again',
    };
  }
}


export async function login(data) {
  const result = await instance.post("/auth/login", data);
  return processResult(result);
}

export async function checkEligibility(data) {
  const result = await instance.post("/product/customercare/check/eligibility", data);
  return processResult(result);
}

export async function checkSubscriptionStatus(data) {
  const result = await instance.post("/product/customercare/check/subscription", data);
  return processResult(result);
}



export async function subscribe(data) {
  const result = await instance.post("/product/customercare/subscribe", data);
  return processResult(result);
}

export async function borrow(data) {
  const result = await instance.post("/product/customercare/borrow", data);
  return processResult(result);
}

export async function borrowHistory(data) {
  const result = await instance.post("/product/customercare/borrow-history", data);
  return processResult(result);
}

//Customer Care Api

export async function contactUsForm(data) {
  const result = await instance.post('/product/contact', data);
  return processResult(result);
}







export default {
  login,
  checkEligibility,
  checkSubscriptionStatus,
  subscribe,
  borrow,
  borrowHistory,
  contactUsForm
};