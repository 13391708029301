const keyWord = 'ETH_CUSTOMER_CARE';



export const getUser = () => {
    return localStorage.getItem(keyWord+"_USER");
}

export const setUser = (user) => {
     localStorage.setItem(keyWord+"_USER", user);
}




export const getToken = () => {
    return localStorage.getItem(keyWord+"_TOKEN");
}

export const getToken2 = () => {
    return localStorage.getItem(keyWord+"_TOKEN2");
}

export const setToken = (token) => {
     localStorage.setItem(keyWord+"_TOKEN", token);
}




export const setToken2 = (token) => {
    localStorage.setItem(keyWord+"_TOKEN2", token);
}



export const clearStorage = () => {
    localStorage.clear();
}

export const removeToken  = () => {
    localStorage.removeItem(keyWord+"_TOKEN");
}


export const getBonusToken = () => {
    return localStorage.getItem(keyWord+"_BONUS");

}


export const setBonusToken = (token) => {
    localStorage.setItem(keyWord+"_BONUS", token);
}


export const removeBonusToken = () => {
    localStorage.removeItem(keyWord+"_BONUS");
}



export const getBonusTokenAmount = () => {
    return localStorage.getItem(keyWord+"_BONUS_AMOUNT");

}


export const setBonusTokenAmount = (token) => {
    localStorage.setItem(keyWord+"_BONUS_AMOUNT", token);

}
export const  removeBonusAmount = () => {
    localStorage.removeItem(keyWord+"_BONUS_AMOUNT");


}


export const getExpiresAt = () => {
    return localStorage.getItem(keyWord+"_EXPIRES_AT");
}

export const setExpiresAt = (expiresAt) => {
     localStorage.setItem(keyWord+"_EXPIRES_AT", expiresAt);
}




export const getRole = () => {
    return localStorage.getItem(keyWord+"_ROLE");
}

export const setRole = (role) => {
     localStorage.setItem(keyWord+"_ROLE", role);
}




export const getPowerDetails = () => {
    return localStorage.getItem("HISA_POWER_OBJECT");

}